<template>
  <div class="revise">
    <!-- navbar组件 -->
    <navbar title="修改信息" />
    <div class="revise-content">
      <van-uploader
        v-model="showImg" max-count="2"
        :deletable="false" :after-read="afterRead"
        :preview-full-image="false" :preview-image="false"
        :max-size="maxFileSize * 1024 *1024" @oversize="onOversize"
        accept="image/*">
        <template #default v-if="user.avatarUrl">
          <img :src="user.avatarUrl" alt="" />
        </template>
      </van-uploader>
      <van-cell-group>
        <van-field v-model="user.realName" left-icon="user-o"  @input="nameFocus" placeholder="请输入姓名"/>
        <van-field v-model="user.mobile" clearable left-icon="phone-o" placeholder="请输入手机号"/>
      </van-cell-group>
      <van-button size="large" @click="saveData">提交</van-button>
    </div>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import { isMobile } from "../../common/validate";

export default {
  name: "revise",
  data() {
    var validMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号号码"));
      } else if (!isMobile(value)) {
        callback(new Error("电话号码格式不正确"));
      } else {
        callback();
      }
    };
    return {
      user: {},
      avatarUpload:"",
      showImg: [],
      maxFileSize:20,//图片大小限制,默认20M
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
      nameFocus(value) {
      this.user.realName = value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, "");
    },
    afterRead(res) {
      this.showImg = [];
      this.user.avatarUrl = res.content;
      this.avatarUpload = res.content;
      this.showImg.push(res);
    },
    getUserInfo() {
      this.$post("user/getUserInfo",{ type: 0},{ toast:true,toastName:"提交中……"}).then((res) => {
        if (res.status == 200) {
          this.user = res.data.userInfo;
          this.maxFileSize = res.data.setting.maxFileSize;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    saveData() {
      var page = this;
      if (!this.user.realName) {
        this.$toast.fail("请输入姓名");
        return false;
      }else if(this.user.realName.length>10){
        this.$toast.fail("姓名长度不能大于"+10+"位");
        return false;
      }
      if (!this.user.mobile) {
        this.$toast.fail("请填写手机号码");
        return false;
      } else if (!isMobile(this.user.mobile)) {
        this.$toast.fail("手机号码格式不正确");
        return false;
      }
      var params = {
        realName: this.user.realName,
        mobile: this.user.mobile,
        avatarUrl: this.avatarUpload,
      };

      this.$post("user/update",params,{ toast:true,toastName:"提交中……"}).then((res) => {
        if (res.status == 200) {
          var router =
          this.$toast.success({
            message:"保存成功",
            duration:1500,
            onClose:function(){
              page.$router.push("/my/index");
            }
          });
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    onOversize(){
       this.$toast('文件大小不能超过'+ this.maxFileSize +'M');
    }
  },
  components: {
    navbar,
  },
};
</script>
<style lang="less" scoped>
.revise {
  min-height: 100vh;
  background: #fff;
  padding: 0 20px;
  .revise-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /deep/.van-uploader {
      margin: 20px 0;
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }
    /deep/[class*="van-hairline"]::after {
      border: unset;
    }
    /deep/.van-cell-group {
      width: 100%;
      .van-cell {
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
      }
      .van-field__left-icon {
        padding: 0 15px;
        color: #aaa;
        .van-icon {
          font-size: 18px;
        }
      }
      input {
        font-size: 16px;
      }
    }
    /deep/.van-button {
      margin-top: 50px;
      border: 0;
      height: 40px;
      background: #00d3c4;
      color: #fff;
      border-radius: 5px;
      letter-spacing: 1px;
    }
  }
}
</style>